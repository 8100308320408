import { getUrl } from '@/api'
import { sessionGet } from '@/utils/storage.js'
import day from 'dayjs'

// 转换选项列表
export function transformOptions(data, flag) {
    let temp, list = options[flag];
    if (!data || !options[flag]) {
        return;
    }

    if (data.length) {
        temp = []
        data.forEach(item => {
            if (item && (item.ID || item.Name)) {
                if (item.ID) {
                    const t = list[item.ID - 1]
                    if (t) {
                        item.Name = t.Name;
                        temp.push(item)
                    }
                } else {
                    temp.push(item)
                }

            }
        })
    } else {
        if (data && (data.ID || data.Name)) {
            if (!data.Name) {
                const t = list[data.ID - 1]
                if (t) {
                    data.Name = t.Name;
                    temp = data
                }

            } else {
                temp = data
            }

        }
    }

    return temp;
}

// 转换附件
export function transformAttachment(data) {
    let temp;
    if (!data) {
        return;
    }
    if (data.length) {
        temp = [];
        data.forEach(item => {
            if (item && item.ID) {
                item.url = getUrl(item.ID)
                temp.push(item)
            }
        })
    } else {
        if (data && data.ID) {
            data.url = getUrl(data.ID)
            temp = { ...data }
        }

    }
    return temp;
}
const options = sessionGet('options') || {}

export function transformKnowledge(data) {
    const detail = data.Detail || {},
        cover = transformAttachment(detail.Cover) || {},
        attachments = transformAttachment(detail.Attachments) || [],
        category = transformOptions(detail.Category, 'KnowledgeClass') || {},
        tags = transformOptions(detail.Tags, 'tag') || [],
        fields = transformOptions(detail.Fields, 'field') || [],
        industries = transformOptions(detail.Industries, 'industry') || [],
        experts = detail.Experts || [];

    const temp = {
        id: data.ID, // 知识id
        name: detail.Title, // 知识名称
        desc: detail.Desc, // 知识描述
        descArray: detail.Desc.split('\n'),
        source: detail.Source, // 知识来源
        sourceLink: detail.Link, // 知识来源网站
        updateTime: day(detail.UpdatedAt).format('YYYY-MM-DD HH:mm'), // 知识更新时间
        publishTime: new Date(detail.PublishedAt).getTime() + '', // 知识发布时间
        publishTimeStr: day(detail.PublishedAt).format('YYYY-MM-DD'),
        author: detail.Author, // 作者
        cover, // 知识logo
        attachments, // 知识附件
        weight: detail.Weight, // 知识权重
        experts: detail.experts || [], // 参与知识的专家
        isRecommend: !!detail.Weight ? 1 : 2, // 是否推荐
        category, // 知识种类
        categoryId: category.ID,
        tags, // 知识所属技术
        TagIDs: tags.filter(item => item.ID).map(item => item.ID),
        Tag: tags.filter(item => !item.ID).map(item => item.Name),
        fields, // 知识所属领域
        FieldIDs: fields.filter(item => item.ID).map(item => item.ID),
        Field: fields.filter(item => !item.ID).map(item => item.Name),
        industries, // 知识所属行业
        IndustryIDs: industries.filter(item => item.ID).map(item => item.ID),
        Industry: industries.filter(item => !item.ID).map(item => item.Name),
        expert: experts.map(item => item.Name)
    }
    if (temp.Industry.length) {
        temp.IndustryIDs.push(-1)
    }
    if (temp.Tag.length) {
        temp.TagIDs.push(-1)
    }
    return temp
}

export function transformExpert(data) {
    const detail = data.Detail || {},
        company = data.Company || {},
        info = company.Info || {},
        logo = transformAttachment(detail.Photo) || '',
        industries = transformOptions(detail.Industries, 'industry') || [],
        fields = transformOptions(detail.Fields, 'expertField') || [],
        services = transformOptions(detail.Services, 'service') || [],
        tags = detail.Tags || [];
    const temp = {
        id: data.ID,
        name: detail.Name,
        title: detail.Title,
        companyName: info.Name,
        companyId: data.CompanyID,
        desc: detail.Desc,
        gender: detail.Gender,
        position: detail.Position,
        visible: +data.visible,
        industries,
        fields,
        tags,
        services,
        logo
    }
    return temp
}

export function transformQuestion(data) {
    const detail = data.Detail || {},
        answer = data.Answer || {},
        industries = transformOptions(detail.Industries, 'industry') || [],
        fields = transformOptions(detail.Fields, 'expertField') || [],
        tags = transformOptions(detail.Tags, 'tag') || [];
    const temp = {
        id: data.ID,
        title: detail.Desc,
        answer: answer.Desc,
        visible: data.Visible,
        industries,
        fields,
        tags,
    }
    return temp
}

export function transformProduct(data) {
    const detail = data.Detail || {},
        company = data.Company || {},
        info = company.Info || {},
        logo = transformAttachment(detail.Photos[0]).url || '',
        industries = transformOptions(detail.Industries, 'industry') || [],
        tags = transformOptions(detail.Tags, 'tag') || [],
        keywords = detail.KeyWords || [],
        attachments = transformAttachment(detail.Attachments) || [],
        details = transformAttachment(detail.Detail) || [];

    const temp = {
        id: data.ID,
        name: detail.Name,
        Industries: industries.map(item => item.Name),
        industryIDs: industries,
        tagIds: tags,
        tags: tags.map(item => item.Name),
        logo,
        companyName: info.Name,
        createTime: day.unix(data.CreateTime).format('YYYY-MM-DD HH:mm'),
        keywords,
        desc: detail.Desc,
        attachments,
        detail: details
    }

    return temp;
}

export function transformComment(data) {
    const detail = data.Detail || {},
        target = data.Target || {},
        user = data.User || {},
        targetPhoto = transformAttachment(target.Photo) || {},
        userPhoto = transformAttachment(user.Profile) || {};

    let answers = data.Answers || [];

    answers = answers.map(transformAnswer)
    return {
        userName: user.Name,
        targetId: target.ID,
        targetName: target.Name,
        targetType: target.Type, // 1 产品 2 案例 3 方案
        targetDesc: target.Desc,
        targetTypeName: getTargetType(target.Type),
        title: detail.Desc,
        status: data.AuditStatus, // 1 待审核 2 审核通过 3 审核拒绝
        statusName: getStatusName(data.AuditStatus),
        updateTime: day(detail.UpdatedAt).format('YYYY-MM-DD HH:mm'),
        createTime: day(data.CreatedAt).format('YYYY-MM-DD HH:mm'),
        visible: data.Public,
        id: data.ID,
        targetPhoto,
        userPhoto,
        answers,
    }
}

function transformAnswer(data) {
    const user = data.User || {};

    const temp = {
        id: data.ID,
        content: data.Content,
        userLogo: transformAttachment(user.Profile) || {},
        userName: user.Name,
        userId: user.ID,
        createTime: day(data.CreatedAt).format('YYYY-MM-DD HH:mm'),
    }
    return temp
}

function getTargetType(type) {
    if (type === 1) {
        return '企业产品'
    } else if (type === 2) {
        return '企业案例'
    } else if (type === 3) {
        return '企业方案'
    }
}

function getStatusName(status) {
    if (status === 0) {
        return '未提交审核'
    } else if (status === 1) {
        return '待审核'
    } else if (status === 2) {
        return '审核通过'
    } else if (status === 3) {
        return '审核拒绝'
    }
}