import { request, proRequest } from '@/utils/request.js'
import { transformProduct,transformComment } from '@/utils/transform';

// 创建产品提问
export function createSolutionComment(id, data) {
    return request.post(`/solutions/${id}/comments`, data)
}

// 获取产品提问
export function getSolutionComment(id, params) {
    return request.get(`/solutions/${id}/comments`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformComment(row))
        return Promise.resolve(temp)
    })
}

// 获取案例列表
export function getSolutions(params) {
    return request.get('/solutions', { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformProduct(row))
        return Promise.resolve(temp)
    })
}

// 获取当前公司案例列表
export function getCurrentSolutions() {
    return request.get('/user/company/solutions')
}

// 获取当前公司案例详情
export function getCurrentSolutionDetail(id) {
    return request.get(`/user/company/solutions/${id}`)
}

// 获取公司案例列表
export function getCompanySolutions(id) {
    return request.get(`/company/${id}/solutions`)
}

// 获取案例详情
export function getSolution(id) {
    return request.get(`/solutions/${id}`).then(({ data }) => {
        return Promise.resolve(transformProduct(data))
    })
}

// 设置案例可见性
export function setSolutionVisible(id,data) {
    return request.post(`/solutions/${id}/visible`,data)
}

// 创建案例
export function createSolution(data) {
    return request.post(`/solutions`, data)
}

// 保存案例
export function saveSolution(data) {
    return request.put(`/solutions/${data.ID}`, data)
}

// 删除案例
export function deleteSolution(id) {
    return request.delete(`/solutions/${id}`)
}

// 提交案例
export function evaluateSolution(id) {
    return request.post(`/solutions/${id}/submit`)
}

// 获取后台案例审核列表
export function getProSolutions(params) {
    return proRequest.get(`/solutions`, { params })
}

// 获取企业案例审核列表
export function getProCompanySolutions(id) {
    return proRequest.get(`/company/${id}/solutions`)
}

// 获取后台案例详情
export function getProSolutionDetail(id) {
    return proRequest.get(`/solutions/${id}`)
}

// 保存后台案例
export function saveProSolution(id, data) {
    return proRequest.put(`/solutions/${id}`, data)
}

// 审核后台案例
export function evaluteProSolution(id, data) {
    return proRequest.post(`/solutions/${id}/audit`, data)
}
